import { IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import FlagLogo from '../../../images/logos/flag-logo.png';
import { HiMenu } from 'react-icons/hi';
import LazyLoadImageFromURL from '../LazyLoadImageFromURL';
import { MdLocalPhone } from 'react-icons/md';
import PhoneIcon from '../../icons/PhoneIcon';
import Toolbar from '@mui/material/Toolbar';
import fetchUrlFromCDN from '../../../services/CDNService';
import { fireEvent } from '../../../cra';
import { getContact } from '../../../hooks/getContact';
import getThemeName from '../../../hooks/useThemeFromUrl';
import { myFormDataStore } from '../../../stores/FormDataStore';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
import { styled as styledMui } from '@mui/material/styles';
import themes from '../../../themes';

const brand = getThemeName();

const headerLogoStyles = {
  width: 'auto',
  height: 'auto',
  maxHeight: '50px',
  maxWidth: '100%',
  display: 'flex',
};

const LogoContainer = styled.div`
  max-width: 200px;
  min-width: 150px;
  @media screen and (max-width: 290px) {
    max-width: 170px !important;
  }
`;

const MyToolbar = styledMui(Toolbar)`
  display: flex;
  justify-content: flex-end;
  flex:1;
  grid-gap: 10px;
  boxSizing: border-box;
  padding-left: 0px !important;
  padding-right: 0px !important;
  @media screen and (max-width: 650px) {
    display: none;
  }
`;

const ToolbarCallArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: -40px;
  @media screen and (max-width: 1000px) {
    margin-right: -10px;
  }
`;

const ToolbarCallDiv = styled.div`
  display: flex;
  grid-gap: 5px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const PhoneIconContainer = styled.div`
  height: 18px;
`;

const HeaderText = styled.div`
  display: flex;
`;

const Container = styled.div`
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px 20px;
`;

const NavWrapper = styled.div`
  flex: 1;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  grid-gap: 50px;
  padding: 10px 30px;
  @media screen and (max-width: 1000px) {
    grid-gap: 10px;
  }
  @media screen and (max-width: 600px) {
    padding: 10px 20px;
  }

  @media screen and (max-width: 345px) {
    padding: 11px 20px;
  }
`;

const CirclePhoneIcon = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border-color: ${getThemeName() === 'aetna-medicare'
    ? '#7d3f98'
    : getThemeName() === 'devoted'
    ? '#FF4F00'
    : '#053870'};
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border: 2px solid;
  float: right;
  .icon {
    color: ${getThemeName() === 'aetna-medicare'
      ? '#7d3f98'
      : getThemeName() === 'devoted'
      ? '#FF4F00'
      : '#053870'};
    transition: color 0.3s;
  }
  &:hover {
    background-color: ${getThemeName() === 'aetna-medicare'
      ? '#7d3f98'
      : getThemeName() === 'devoted'
      ? '#FF4F00'
      : '#053870'};
  }
  &:hover .icon {
    color: white;
  }
  @media screen and (min-width: 650px) {
    display: none;
  }
`;

const BurgerWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    display: flex;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
`;

function Header(props) {
  const [imageUrl, setImageUrl] = useState();
  const [isButtonHovering, setIsButtonHovering] = useState(false);
  const { isCarrierSpecific, carrierName } = props;

  useEffect(() => {
    fetchUrlFromCDN(brand)
      .then((resp) => {
        'logo' in resp && setImageUrl(resp.logo);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
        position: 'sticky',
        top: 0,
      }}
      elevation={0}
      color='inherit'
      style={{ zIndex: '1999' }}
    >
      {isCarrierSpecific && (
        <Container
          style={{
            backgroundColor:
              carrierName === 'Aetna'
                ? '#7D3F98'
                : carrierName === 'Devoted Health'
                ? '#FF4F00'
                : '#053870',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Inter, sans-serif',
              fontSize: '12px',
              fontWeight: '500',
            }}
          >
            {carrierName === 'medicareassociation'
              ? 'This website is run by Elite Insurance Partners. We are proud to have earned the trust of the American Association for Medicare Supplement Insurance.'
              : `This website is run by Elite Insurance Partners, licensed to sell ${carrierName}® Medicare products`}
          </Typography>
        </Container>
      )}
      <HeaderContainer>
        <BurgerWrapper>
          <IconButton
            color='primary'
            onClick={() => props.handleDrawerToggle()}
          >
            <HiMenu />
          </IconButton>
        </BurgerWrapper>
        <NavWrapper>
          <LogoContainer
            style={{
              maxWidth:
                carrierName === 'medicareassociation' ? '300px' : '325px',
            }}
          >
            <LazyLoadImageFromURL
              imageUrl={imageUrl}
              fallbackSrc={FlagLogo}
              alt='logo'
              className={'logo'}
            />
          </LogoContainer>
          <MyToolbar>
            <ToolbarCallArea>
              <HeaderText>
                <Typography
                  color='primary'
                  fontWeight={500}
                  style={{ fontSize: '14px', textAlign: 'right' }}
                >
                  Speak with a Licensed Insurance Agent
                </Typography>
              </HeaderText>
              <a
                href={getContact(getThemeName()).href}
                style={{ textDecoration: 'none' }}
              >
                <ToolbarCallDiv
                  onMouseOver={() => {
                    setIsButtonHovering(true);
                  }}
                  onMouseOut={() => {
                    setIsButtonHovering(false);
                  }}
                  onClick={(e) => {
                    fireEvent('initiateCall', e, {
                      description: `Header Call Button - Request Callback`,
                      value: getContact(getThemeName()).text,
                    });
                  }}
                  style={{
                    cursor: isButtonHovering && 'pointer',
                  }}
                >
                  <PhoneIconContainer>
                    <PhoneIcon color={themes(brand).palette.primary.main} />
                  </PhoneIconContainer>
                  <Typography
                    color={isButtonHovering ? 'primary' : 'secondary'}
                    fontWeight='bold'
                    noWrap
                    className='phone-number-fade'
                    sx={{ textDecoration: 'none', fontSize: '18px' }}
                  >
                    {getContact(getThemeName()).text}
                  </Typography>
                </ToolbarCallDiv>
              </a>
            </ToolbarCallArea>
          </MyToolbar>
          <a
            href={getContact(getThemeName()).href}
            style={{ textDecoration: 'none' }}
          >
            <CirclePhoneIcon
              onMouseOver={() => {
                setIsButtonHovering(true);
              }}
              onMouseOut={() => {
                setIsButtonHovering(false);
              }}
              onClick={(e) => {
                fireEvent('initiateCall', e, {
                  description: `Header Call Button - Request Callback`,
                  value: getContact(getThemeName()).text,
                });
              }}
              style={{
                cursor: isButtonHovering && 'pointer',
                borderColor:
                  getThemeName() === 'aetna-medicare'
                    ? '#7d3f98'
                    : getThemeName() === 'devoted'
                    ? '#FF4F00'
                    : '#053870',
              }}
            >
              <MdLocalPhone className='h-6 w-6 icon' />
            </CirclePhoneIcon>
          </a>
        </NavWrapper>
      </HeaderContainer>
    </AppBar>
  );
}

export default observer(Header);
