import React, { useEffect, useState } from 'react';

import skeletonLoader from '../../images/logos/skeleton-loader.gif';

function LazyLoadImageFromURL({
  imageUrl,
  fallbackSrc,
  alt,
  style = {},
  className = '',
}) {
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageError(false);
  }, [imageUrl]);
  return (
    <>
      {!imageUrl && <img src={skeletonLoader} alt='loading...' style={style} />}

      {imageUrl && (
        <img
          src={imageError ? fallbackSrc : imageUrl}
          onError={() => setImageError(true)}
          loading='lazy'
          alt={alt}
          className={`w-full ${className}`}
          style={style}
        />
      )}
    </>
  );
}

export default LazyLoadImageFromURL;
