import './stepper.css';

import * as React from 'react';

import Box from '@mui/material/Box';
import { FaRegCircle } from 'react-icons/fa';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import RadioCheck from '../../icons/RadioCheck';
import RadioCurrent from '../../icons/RadioCurrent';
import { ReactComponent as RadioEmptyIcon } from '../../../icons/radio_empty.svg';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import { StepState } from '../../../data/enums.ts';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
// import styled from "@emotion/styled";
import emotionStyled from '@emotion/styled';
import getThemeName from '../../../hooks/useThemeFromUrl';
import { myFormDataStore } from '../../../stores/FormDataStore.tsx';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
// import { styled } from '@mui/material/styles';
import themes from '../../../themes';

const brand = getThemeName();

const ColorlibStepIconRoot = emotionStyled('div')(({ theme, ownerState }) => ({
  display: 'flex',
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  let icon;
  if (active)
    icon = <RadioCurrent color={themes(brand).palette.primary.main} />;
  else if (completed)
    icon = <RadioCheck color={themes(brand).palette.primary.main} />;
  else icon = <RadioEmptyIcon />;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icon}
    </ColorlibStepIconRoot>
  );
}

const Container = emotionStyled.div`
  max-width: 400px;
  padding: 0px 20px;

  .MuiStepLabel-alternativeLabel {
    flex-direction: row;
  }
`;

const StyledStep = styled(Step)(() => ({
  '& .MuiStepLabel-alternativeLabel': {
    flexDirection: 'row !important',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginLeft: '2.5px',
    textAlign: 'left',
    gap: '0.5rem',
  },
}));

const Link = styled.p`
  padding: 10px 0px;
  color: #515151;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  &:hover {
    color: #222c69;
  }
`;

function MyStepper({ sectionIndex, handleDrawerToggle }) {
  const handleOnClick = (stepIndex) => {
    myFormDataStore.handleClickStep(sectionIndex, stepIndex);
  };
  return (
    <Container>
      {myFormDataStore.accordionData[sectionIndex].steps
        .filter((step) => step.isVisible)
        .map((step, index) => (
          <Link
            key={step.label}
            onClick={() => {
              handleOnClick(index);
              if (handleDrawerToggle) handleDrawerToggle();
            }}
          >
            <span>{step.label}</span>
            <span>
              {(step.stepState === StepState.Pending ||
                step.stepState === StepState.Skipped ||
                step.stepState === StepState.WIP) && (
                <FaRegCircle style={{ color: '#D9D9D9', fontSize: '18px' }} />
              )}
              {step.stepState === StepState.Completed && (
                <IoIosCheckmarkCircle
                  style={{ color: '#459308', fontSize: '20px' }}
                />
              )}
            </span>
          </Link>
        ))}
    </Container>
  );
}

export default observer(MyStepper);
