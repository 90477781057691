import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import Footer from '../components/common/Footer/Footer';
import Header from '../components/common/Header/Header';
import MobileNav from '../components/common/MobileNav';
import MyAccordion from '../components/common/Accordion';
import ProgressIndicator from '../components/common/ProgressIndicator';
import QuestionPage from '../components/home/QuestionPage';
import SEO from '../components/Seo';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const PageContent = styled.div`
  display: flex;
  width: '100%';
  min-height: 100vh;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) =>
    props.isCarrierSpecific ? 'calc(100vh - 112px)' : 'calc(100vh - 80px)'};
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
  padding: 0px 20px;
  max-width: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  position: sticky;
  z-index: 100;
  top: ${(props) => (props.isCarrierSpecific ? '112px' : '80px')};

  @media screen and (max-width: 830px) {
    max-width: 260px;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const MobileProgressIndicator = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;

const DrawerHeader = styled.div`
  padding: 20px 5px 20px 20px;
  display: flex;
  justify-content: space-between;
`;

function HomePage({ routeToQuotingTool }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isCarrierSpecific, setIsCarrierSpecific] = useState(false);
  const [carrierName, setCarrierName] = useState('');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getCarrierName = (brandName) => {
    return brandName === 'aetna-medicare'
      ? 'Aetna'
      : brandName === 'mutual of omaha-medicare'
      ? 'Mutual of Omaha'
      : brandName === 'medicareassociation'
      ? 'medicareassociation'
      : brandName === 'devoted'
      ? 'Devoted Health'
      : '';
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const brandName = params.has('leadBrand') ? params.get('leadBrand') : '';
    if (
      brandName &&
      (brandName.startsWith('aetna') ||
        brandName.startsWith('mutual') ||
        brandName.startsWith('medicareassociation') ||
        brandName.startsWith('devoted'))
    ) {
      const carrierName = getCarrierName(brandName);

      setCarrierName(carrierName);
      setIsCarrierSpecific(true);
    }
  }, []);

  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box>
      <SEO title='Medicare 101' />
      <Header
        isCarrierSpecific={isCarrierSpecific}
        carrierName={carrierName}
        handleDrawerToggle={handleDrawerToggle}
      />

      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          zIndex: 10000,

          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            padding: '0px 20px',
          },
        }}
      >
        <DrawerHeader>
          <Typography fontWeight={600}>Sections</Typography>
          <CloseIcon
            style={{ paddingRight: '12px', width: '18px' }}
            onClick={handleDrawerToggle}
          />
        </DrawerHeader>
        <MyAccordion handleDrawerToggle={handleDrawerToggle} />
      </Drawer>

      <PageContainer>
        <MobileNav />
        <MobileProgressIndicator>
          <ProgressIndicator />
        </MobileProgressIndicator>
        <PageContent>
          <LeftSection isCarrierSpecific={isCarrierSpecific}>
            <ProgressIndicator />
            <MyAccordion />
          </LeftSection>
          <QuestionPage
            handleDrawerToggle={handleDrawerToggle}
            isDrawerOpen={mobileOpen}
            routeToQuotingTool={routeToQuotingTool}
          />
        </PageContent>
        <Footer />
      </PageContainer>
    </Box>
  );
}

export default HomePage;
