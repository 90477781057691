import { AccordionSummary, Typography, colors } from '@mui/material';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import MuiAccordion from '@mui/material/Accordion';
import MyStepper from './Stepper/Stepper';
import React from 'react';
import { StepState } from '../../data/enums';
import getThemeName from '../../hooks/useThemeFromUrl';
import { myFormDataStore } from '../../stores/FormDataStore.tsx';
// import { trace } from "mobx";
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
import { styled as styledMui } from '@mui/material/styles';
import { default as themes } from '../../themes';

const brand = getThemeName();

const Accordion = styledMui((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  textAlign: 'left',
  '.MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const IconWrapper = styled.div`
  height: 20px;
`;

function MyAccordion(props) {
  let temp = myFormDataStore.accordionData.map((a, i) => {
    return i === 0 ? true : false;
  });

  return (
    <>
      {myFormDataStore.accordionData.map((section, i) => (
        <Accordion
          key={`accordion-${i}`}
          expanded={myFormDataStore.accordionData[i].isExpanded}
          sx={{
            backgroundColor: '#FAFCFF',
            border: '0px',
            margin: '8px 0px',
            borderRadius: '8px',
            '& .MuiAccordionSummary-content': {
              flexDirection: 'row-reverse',
              gap: '10px',
            },
          }}
        >
          <AccordionSummary
            onClick={() => {
              myFormDataStore.toggleExpanded(i);
            }}
            sx={{
              backgroundColor: '#F2F7FE',
              border: '0px',
              padding: '8px',
              borderRadius: '8px',
            }}
          >
            <Typography
              color='primary'
              fontWeight={600}
              fontSize={themes(brand).typography.myFontSizes.h5}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: '20px',
                fontSize: '16px',
              }}
            >
              <span>{section.title}</span>
              <span
                style={{
                  fontSize: '14px',
                  paddingRight: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {
                  myFormDataStore.accordionData[i].steps.filter(
                    (step) =>
                      step.isVisible && step.stepState === StepState.Completed
                  ).length
                }
                /{myFormDataStore.accordionData[i].steps.length}
              </span>
            </Typography>

            {myFormDataStore.accordionData[i].isExpanded ? (
              <IconWrapper>
                <MdKeyboardArrowUp style={{ height: '100%' }} />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <MdKeyboardArrowDown style={{ height: '100%' }} />
              </IconWrapper>
            )}
          </AccordionSummary>
          <MyStepper
            sectionIndex={i}
            handleDrawerToggle={props.handleDrawerToggle}
          />
        </Accordion>
      ))}
    </>
  );
}

export default observer(MyAccordion);
